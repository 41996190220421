/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        //$('html').mCustomScrollbar();

        window.sr = ScrollReveal();
        var revealOptions = {
          easing: 'ease',
          distance: '20px',
          mobile: false,
          delay: 500,
          duration: 1400,
          reset: false,
          interval: 120
        };

        var revealFooterOptions = {
          easing: 'ease',
          distance: '20px',
          mobile: false,
          delay: 500,
          duration: 1000,
          reset: false,
          interval: 60
        };

        var revealillustrationOptions = {
          easing: 'ease',
          distance: '10px',
          mobile: false,
          delay: 500,
          duration: 1400,
          reset: false,
          interval: 60
        };

        var initAnimationinit = function initAnimationinit() {

          sr.reveal('header .brand, header .navbar-nav a', revealOptions);
          sr.reveal('.homepage-banner h2,.homepage-banner p, .homepage-banner a', revealOptions);
          sr.reveal('.homepage-content-boxes--box-1 h4,.icon-link-description-row--col *', revealOptions);
          sr.reveal('.homepage-content-boxes--box-2 h3,.homepage-content-boxes--box-2 h4, .illustrations-explanation-box__content *', revealillustrationOptions);
          sr.reveal('.homepage-content-boxes--box-3 *', revealOptions);
          sr.reveal('.main-heading, .text-content, .sub-heading, .btn', revealOptions);
          sr.reveal('.team__member-img, .team__member-name, .team__member-title, .team__member-experience', revealOptions);
          sr.reveal('.search-form, .categories-links a, .entry-title, .updated, .entry-summary, .news__post-toggle, .news__post-social-links a, .news__post-category', revealOptions);
          sr.reveal('.comment-reply-title, .comment-form *, .sub-heading, .comment *', revealOptions);
          sr.reveal('.tabbed-content, .tabbed-content li, .tabbed-content__tab-content, .how-it-works-page-link, .how-it-works-posts__post > *', revealOptions);
          sr.reveal('.collaborate .main-heading, .collaborate .text-content, .collaborate .btn, footer h3, .footer p, .footer a, .footer label, .footer input', revealFooterOptions);
        };

        initAnimationinit();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var formValidationEngine = function formValidationEngine() {
          $(".wpcf7-form").validationEngine('attach', {
            scroll: false,
            promptPosition: "topLeft",
            maxErrorsPerField: 1
          });
        };
        formValidationEngine();

        var inputLabel = $('.wpcf7-form label');
        var inputField = $('.wpcf7-form input[type="email"]');

        inputLabel.click(function () {
          $(this).parents('.form-fields').find('input').focus();
        });

        inputField.focus(function () {
          $(this).parents('.form-fields').find('label').addClass('move-placeholder-at-top');
        });

        inputField.blur(function () {
          var $this = $(this);
          if ($this.val().length == 0) {
            $(this).parents('.form-fields').find('label').removeClass('move-placeholder-at-top');
          } else {
            $(this).parents('.form-fields').find('label').addClass('move-placeholder-at-top');
          }
        });


      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    'blog': {
      init: function () {
        var postCollapse = function postCollapse() {
          $('.news__post-content').height(function (i, h) {
            this.dataset.height = h;
            return h;
          });

          $('.news__post-toggle').on('click', function (e) {
            e.preventDefault();
            var $postToggle = $(this);
            var $postCollapse = $('.' + $postToggle.data('controls'));
            var $post = $postToggle.closest('.post');
            var $postExcerpt = $post.find('.news__post-excerpt');
            var $postContent = $post.find('.news__post-content');
            $postCollapse.each(function () {
              var $thisPostCollapse = $(this);
              if (!$thisPostCollapse.hasClass('in')) {
                $postExcerpt.removeClass('has-ellipsis');
                //$thisPostCollapse.slideDown(function() {
                if ($thisPostCollapse.is('.news__post-remainder')) {
                  $thisPostCollapse.removeClass('js-post-collapse')
                } else {
                  $thisPostCollapse.css('display', 'flex');
                }

                $thisPostCollapse.addClass('in')
                  .attr('aria-expanded', 'true');
                $postToggle.attr('aria-expanded', 'true')
                  .siblings('.news__post-content')
                  .addClass('is-expanded');
                //});
                $postContent.animate({ height: $postContent.get(0).scrollHeight });
              } else {
                $postContent.animate({ height: +$postContent.data('height') }, 400, function () {
                  $thisPostCollapse.removeClass('in')
                    .attr('aria-expanded', 'false')
                    .addClass('js-post-collapse');
                  $postExcerpt.addClass('has-ellipsis');
                  $postToggle.attr('aria-expanded', 'false')
                    .siblings('.news__post-content')
                    .removeClass('is-expanded');
                });
              }
            });
          });
        };

        postCollapse();
      }
    },
    'feedback': {
      init: function () {
        var handleFileChange = function handleFileChange() {
          var container = document.querySelector('.comment-attachment-upload-container');
          var input = document.querySelector('#attachment');
          if (!container || !input) {
            return;
          }

          input.addEventListener('change', function (e) {
            var fileName = '';
            if (this.files && this.files.length > 1) {
              console.log(this.files);
            } else {
              fileName = e.target.value.split('/').pop();
            }

            if (fileName) {
              var fileNameSpan = document.createElement('span');
              fileNameSpan.classList.add('file-name');
              fileNameSpan.textContent = fileName;
              container.appendChild(fileNameSpan);
            } else {
              var fileNameSpan = document.querySelector('.file-name');
              fileNameSpan.remove();
            }
          });
        };

        var turnOffScrollRevealAfterCommentSubmit = function turnOffScrollRevealAfterCommentSubmit() {
          if (window.location.hash.indexOf('#comment') === 0) {
            window.sr.destroy();
          }
        };

        handleFileChange();
        turnOffScrollRevealAfterCommentSubmit();
      }
    },
    'post_type_archive_how_it_works': {
      init: function () {
        var transitionTabPanelsOnTabClick = function transitionTabPanelsOnTabClick() {
          var linksHeight = $('.tabbed-content__tab-links').height();
          $('.tabbed-content').on('click', '.tabbed-content__tab-link', function () {
            var $selectedPanel = $(this.hash);
            $('.tabbed-content__tab-content:not(.is-active)').css({ transition: '0.7s opacity', top: 'calc(100% + 60px)' }).attr('aria-expanded', 'false');
            $('.tabbed-content__tab-content.is-active').css({ transition: '0.7s', top: 'calc(-100% - 60px)' }).removeClass('is-active').attr('aria-expanded', 'false');
            $('.tabbed-content').animate({ height: (Math.max(linksHeight, $selectedPanel.height())) + 120 }, 700, 'linear');
            $selectedPanel.css({ transition: '0.7s', top: 0 }).addClass('is-active');
            $('.tabbed-content__tab-link.is-active').removeClass('is-active').attr('aria-selected', 'false');
            $(this).addClass('is-active').attr('aria-selected', 'true');
          }).height(Math.max(linksHeight, $('.tabbed-content__tab-content:first-of-type').height()));
        };

        transitionTabPanelsOnTabClick();
      },
    },
    'single_how_it_works': {
      init: function () {
        var initSliders = function initSliders() {
          $('.how-it-works-post-tabs__tab-slider').each(function(i, slider) {
            $(slider).slick();
          });
        };

        var setSliderPositionOnTabClick = function setSliderPositionOnTabClick() {
          var timer;
          $('.how-it-works-post-tabs__nav-tabs').on('click', '.how-it-works-post-tabs__nav-link', function() {
            var id = this.id.split('-').join('-pane-');
            clearTimeout(timer);
            timer = setTimeout(function() {
              $('#' + id).find('.slick-initialized').slick('setPosition');
            }, 700);
          });
        };

        initSliders();
        setSliderPositionOnTabClick();
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
